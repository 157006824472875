<mat-toolbar color="primary">
  <div fxHide.gt-sm>
    <button mat-icon-button (click)="onToggleSidenav()">
      <mat-icon>menu</mat-icon>
    </button>
  </div>
  <a routerLink="/"><img src="/assets/tr-logo.svg" /></a>
  <div fxFlex fxLayout fxLayoutAlign="flex-end" fxHide.sm>
    <button mat-button routerLink="" [fragment]="'faq'">Faq</button>

    @for (menu of headerMenu$ | async; track menu) {
      <button mat-button [routerLink]="[menu.link]" routerLinkActive="active">
        {{ menu.title }}
      </button>
    }
    @if (!(isAuth$ | async)) {

      <a mat-button routerLink="/authorisation/sign-up">Signup</a>
      <a mat-button routerLink="/authorisation/sign-in">
        <mat-icon>account_circle</mat-icon>
        Login
      </a>
    } @else {
      <a mat-button [matMenuTriggerFor]="usermenu">
        <mat-icon>more_vert</mat-icon>
      </a>
      <mat-menu #usermenu>
        <button mat-menu-item routerLink="/user-settings">Settings</button>
        <button mat-menu-item (click)="onLogout()">Logout</button>
        <button
          mat-menu-item
          routerLink="/authorisation/change-password/normal"
        >
          Change password
        </button>
      </mat-menu>
    }
  </div>
</mat-toolbar>
