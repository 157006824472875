<mat-sidenav-container class="all-wrap">
  <mat-sidenav #sidenav role="navigation">
    <app-sidenav-list (closeSidenav)="sidenav.close()"></app-sidenav-list>
  </mat-sidenav>

  <mat-sidenav-content class="page-wrap">
    <app-header (sidenavToggle)="sidenav.toggle()"></app-header>
    <main class="content">
      <router-outlet></router-outlet>
    </main>
    <app-footer></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>
