// Representation of the entire app state
// Extended by lazy loaded modules
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { authReducer, AuthState } from '../authorisation/state/auth.reducer';
import { customerReducer, CustomerState } from '../customers/state/customer.reducer';
import { navigationReducer, NavigationState } from '../navigation/state/navigation.reducer';
import { organisationReducer, OrganisationState } from '../organisations/state/organisation.reducer';
import { optionsReducer, OptionsState } from '../question-options/state/options.reducer';
import { questionsReducer, QuestionsState } from '../questions/state/questions.reducer';
import { reportReducer, ReportState } from '../reports/state/report.reducer';
import { templateReducer, TemplateState } from '../templates/state/template.reducer';
import { userReducer, UserState } from '../users/state/user.reducer';
import { initStateFromLocalStorage } from './init-state-from-local-storage.reducer';
import { environment } from '../../environments/environment';
import { debugMetaReducer } from './debug.reducer';

export interface State {

}

export const metaReducers: MetaReducer<AppState>[] = [
  initStateFromLocalStorage
];

if (!environment.production) {
    metaReducers.unshift(debugMetaReducer);
}

export const enum CallingState {
  INIT = 'INIT',
  CALLING = 'CALLING',
  CALLED = 'CALLED',
}

export interface ErrorState {
  errorMsg: string;
}

export type CallState = CallingState | ErrorState;

// Helper function to extract error, if there is one.
export function getError(callState: CallState): string | null {
  if ((callState as ErrorState).errorMsg !== undefined) {
    return (callState as ErrorState).errorMsg;
  }
  return null;
}

// @ts-ignore
// @ts-ignore
export const reducers: ActionReducerMap<AppState> = {
  // @ts-ignore
  auth: authReducer,
  // @ts-ignore
  customer: customerReducer,
  // @ts-ignore
  nav: navigationReducer,
  // @ts-ignore
  organisation: organisationReducer,
  // @ts-ignore
  options: optionsReducer,
  // @ts-ignore
  question: questionsReducer,
  // @ts-ignore
  report: reportReducer,
  // @ts-ignore
  template: templateReducer,
  // @ts-ignore
  users: userReducer
};

export interface AppState {
  auth: AuthState;
  customer: CustomerState;
  nav: NavigationState;
  organisation: OrganisationState;
  options: OptionsState;
  question: QuestionsState;
  report: ReportState;
  template: TemplateState;
  users: UserState;
}
