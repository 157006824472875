import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { HeaderComponent } from './navigation/header/header.component';
import { SidenavListComponent } from './navigation/sidenav-list/sidenav-list.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AuthService } from './authorisation/authorisation.service';
import { FooterComponent } from './navigation/footer/footer.component';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NavigationEffects } from './navigation/state/navigation.effects';
import { EffectsModule } from '@ngrx/effects';
import { NavigationService } from './navigation/navigation.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { navigationReducer } from './navigation/state/navigation.reducer';
import { TokenInterceptor } from './authorisation/token.interceptor';

import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FormsModule } from '@angular/forms';
import { authReducer } from './authorisation/state/auth.reducer';
import { AuthEffects } from './authorisation/state/auth.effects';
import { metaReducers, reducers } from './state/app.state';
import {
  MatIconRegistrationService
} from '@touch-apps/shared/ui/icon-registry/src/lib/icon-registry/mat-icon-registry.service';
import { MatIconModule } from '@angular/material/icon';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidenavListComponent,
    FooterComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    MaterialModule,
    FlexLayoutModule,
    NgxChartsModule,
    FormsModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    EffectsModule.forRoot([]),
    StoreModule.forFeature('nav', navigationReducer),
    StoreModule.forFeature('auth', authReducer),
    // StoreModule.forFeature('report', reportReducer),
    EffectsModule.forFeature([NavigationEffects, AuthEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 10,
      connectInZone: true,
    }),
    AppRoutingModule,
    MatIconModule,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem,
  ],
  providers: [
    AuthService,
    NavigationService,
    MatIconRegistrationService,
    AuthEffects,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {}
}
