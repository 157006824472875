import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../state/app.state';
import * as fromAuth from '../../authorisation/state';
import * as fromNav from '../state';

import { MenuItem } from '../navigation.model';
import * as fromAuthActions from '../../authorisation/state/auth.actions';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Output() sidenavToggle = new EventEmitter<void>();
  isAuth$!: Observable<boolean>;
  headerMenu$!: Observable<MenuItem[]>;

  constructor(private store: Store<fromRoot.State>) { }

  ngOnInit() {
   this.isAuth$ = this.store.select(fromAuth.getIsAuth);
   this.headerMenu$ = this.store.select(fromNav.getHeaderMenu);
  }

  onToggleSidenav() {
    this.sidenavToggle.emit();
  }

  onLogout() {
   // this.authService.logout();
   this.store.dispatch(new fromAuthActions.Logout());
  }

}
