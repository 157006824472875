import { Injectable } from '@angular/core';

import { MenuItem } from './navigation.model';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
@Injectable()
export class NavigationService {

  leadMenu: MenuItem [] = [
    {link: '/dashboard', title: 'Dashboard', icon: 'dashboard'},
    {link: '/customers', title: 'Customers', icon: 'factory'},
    {link: '/question-options', title: 'Options', icon: 'list'},
    {link: '/templates', title: 'Templates', icon: 'fact_check'},
    {link: '/reports', title: 'Reports', icon: 'assignment_turned_in'},
    {link: '/users', title: 'Users', icon: 'account_circle'}
  ];

  userMenu: MenuItem [] = [
    {link: '/dashboard', title: 'Dashboard'},
    {link: '/reports', title: 'Reports'}
  ];

  constructor() {

  }

  getUnauthorisedMenu(): Observable<MenuItem []> {
    return of ([]);
  }

  getHeaderMenuForAdmin(): Observable<MenuItem []> {
    return  of ([
      {link: '/organisations', title: 'Organisations'},
      ...this.leadMenu
    ]);
  }

  getHeaderMenuForLead(): Observable<MenuItem []> {
    return of ([
      ...this.leadMenu
    ]);
  }

  getHeaderMenuForUser(): Observable<MenuItem []> {
    return of ([
      ...this.userMenu
    ]);
  }
}
