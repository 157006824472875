import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import * as fromRoot from '../../state/app.state';

import { NavigationService } from '../navigation.service';
import { NavigationActionTypes } from './navigation.actions';
import * as fromNavActions from '../state/navigation.actions';
import { getHeaderNavState } from './index';
import { LocalStorageService } from '@touch-apps/local-storage';

export const NAV_KEY = 'NAV';

@Injectable()
export class NavigationEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromRoot.State>,
    private nav: NavigationService,
    private localStorageService: LocalStorageService,
  ) {}

  persist = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          NavigationActionTypes.SET_LEAD_MENUS,
          NavigationActionTypes.SET_ADMIN_MENUS,
          NavigationActionTypes.SET_USER_MENUS,
          NavigationActionTypes.SET_HEADER,
          NavigationActionTypes.SET_SIDEMENU,
        ), // todo - add any action here which needs to save to local
        withLatestFrom(this.store.pipe(select(getHeaderNavState))),
        tap(([action, navState]) => {
          console.log('should be saving nav state', navState);
          this.localStorageService.setItem(NAV_KEY, navState);
        }),
      ),
    { dispatch: false },
  );

  // @Effect()
  setAdminMenus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationActionTypes.SET_ADMIN_MENUS),
      switchMap(() => {
        return this.nav
          .getHeaderMenuForAdmin()
          .pipe(
            mergeMap((menus) => [
              new fromNavActions.SetHeader(menus),
              new fromNavActions.SetSideMenu(menus),
            ]),
          );
      }),
    ),
  );

  // @Effect()
  setLeadMenus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationActionTypes.SET_LEAD_MENUS),
      switchMap(() => {
        return this.nav
          .getHeaderMenuForLead()
          .pipe(
            mergeMap((menus) => [
              new fromNavActions.SetHeader(menus),
              new fromNavActions.SetSideMenu(menus),
            ]),
          );
      }),
    ),
  );

  // @Effect()
  setUserMenus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationActionTypes.SET_USER_MENUS),
      switchMap(() => {
        return this.nav
          .getHeaderMenuForUser()
          .pipe(
            mergeMap((menus) => [
              new fromNavActions.SetHeader(menus),
              new fromNavActions.SetSideMenu(menus),
            ]),
          );
      }),
    ),
  );

  // @Effect()
  clearMenus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationActionTypes.CLEAR_MENUS),
      switchMap(() => {
        return this.nav
          .getUnauthorisedMenu()
          .pipe(
            mergeMap((menus) => [
              new fromNavActions.SetHeader(menus),
              new fromNavActions.SetSideMenu(menus),
            ]),
          );
      }),
    ),
  );
}
