import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from '../../state/app.state';
import * as fromNav from './navigation.reducer';

export interface State extends fromRoot.State {
    nav: fromNav.NavigationState;
}

export const getHeaderNavState = createFeatureSelector<fromNav.NavigationState>('nav');
export const getHeaderMenu = createSelector(getHeaderNavState, (state) => state.headerMenuItems);
export const getSideMenu = createSelector(getHeaderNavState, (state) => state.sideMenuItems);
export const showFooter = createSelector(getHeaderNavState, (state) => state.showFooter);
