import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanLoad,
  Route, Router
} from '@angular/router';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

import * as fromRoot from './state';
import * as fromAuth from './state/';
import { Observable } from 'rxjs';
import { roles } from '@touch-apps/user-model';

@Injectable()
export class AuthGuard implements CanActivate, CanLoad {
  constructor(private store: Store<fromRoot.State>, private route: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkIfRoleAllowed(next.data['role']);
  }

  canLoad(route: Route) {
    // @ts-ignore
    return this.checkIfRoleAllowed(route.data['role']);
  }

  checkIfRoleAllowed(role: roles) {
    switch (role) {
      case roles.user:
        return this.store.select(fromAuth.getIsUser).pipe(take(1));
      case roles.lead:
        return this.store.select(fromAuth.getIsLead).pipe(take(1));
      case roles.admin:
        return this.store.select(fromAuth.getIsAdmin).pipe(take(1));
      default:
        this.route.navigate(['/']);
        return false;
    }
  }
}
