<mat-nav-list>
  @for (menu of sideMenu$ | async; track menu) {
    <a
      mat-list-item
      routerLink="{{ menu.link }}"
      (click)="onClose()"
      routerLinkActive="active"
    >
      <mat-icon fontSet="material-icons-outlined">{{ menu.icon }}</mat-icon>
      <span class="nav-caption">{{ menu.title }}</span>
    </a>
  }

  @if (!(isAuth$ | async)) {
    <a mat-list-item routerLink="/authorisation/sign-up" (click)="onClose()">
      <mat-icon fontSet="material-icons-outlined">app_registration</mat-icon>
      <span class="nav-caption">Signup</span>
    </a>
    <a mat-list-item routerLink="/authorisation/sign-in" (click)="onClose()">
      <mat-icon fontSet="material-icons-outlined">login</mat-icon>
      <span class="nav-caption">Signin</span>
    </a>
  } @else {
    <a mat-menu-item routerLink="/user-settings" (click)="onClose()" routerLinkActive="active">
      <mat-icon fontSet="material-icons-outlined">settings</mat-icon>
      <span class="nav-caption">Settings</span>
    </a>
    <a mat-list-item (click)="onLogout()" routerLinkActive="active">
      <mat-icon fontSet="material-icons-outlined">logout</mat-icon>
      <span class="nav-caption">Logout</span>
    </a>
  }
</mat-nav-list>
