import { ActionReducer, INIT, UPDATE } from '@ngrx/store';
import { LocalStorageService } from '@touch-apps/local-storage';
import { AppState } from './app.state';

export function initStateFromLocalStorage(
  reducer: ActionReducer<AppState>,
): ActionReducer<AppState> {
  return function (state, action) {
    console.log ('init state', state, ' action', action.type)
    const newState = reducer(state, action);
    if ([INIT.toString(), UPDATE.toString()].includes(action.type)) {
      return { ...newState, ...LocalStorageService.loadInitialState() };
    }
    return newState;
  };
}
