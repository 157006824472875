import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { MenuItem } from '../navigation.model';
import * as fromRoot from '../../state/app.state';
import * as fromAuth from '../../authorisation/state';
import * as fromNav from '../../navigation/state';

import { Store } from '@ngrx/store';
import * as fromAuthActions from '../../authorisation/state/auth.actions';

@Component({
  selector: 'app-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.scss']
})
export class SidenavListComponent implements OnInit {
  @Output() closeSidenav = new EventEmitter<void>();
  sideMenu$!: Observable<MenuItem[]>;
  isAuth$!: Observable<boolean>;

  constructor(private store: Store<fromRoot.State>) { }

  ngOnInit() {
    this.isAuth$ = this.store.select(fromAuth.getIsAuth);
    this.sideMenu$ = this.store.select(fromNav.getSideMenu);
  }

  onClose() {
    this.closeSidenav.emit();
  }

  onLogout() {
    this.onClose();
    this.store.dispatch(new fromAuthActions.Logout());
  }
}
