import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { AuthService } from './authorisation.service';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // console.log('token interceptor run');
    // console.log('header before', request);

    // test here for S3 put and do not add the token on pre-signed urls
    // request.method = 'PUT'
    // request.url contains s3.region
    if (
      request.method === 'PUT' &&
      request.url.includes(`s3.${environment.region}`)
    ) {
      // console.log('s3 put');
      return next.handle(request);
    }

    // todo - get the session from state
    // @ts-ignore
    return this.auth.getAuthenticatedUser().getSession((err, session) => {
      if (err) {
        // console.log('Error in egtSession', err);
        return next.handle(request);
      }
      const headers = request.headers.set(
        'Authorization',
        `${session.getIdToken().getJwtToken()}`
      );
      const authReq = request.clone({ headers });
      // console.log('auth request', authReq);
      return next.handle(authReq);
    });
  }
}
