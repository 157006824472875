import { BaseAttributes } from '@touch-apps/common-model';

export enum UserRoles {
  Admin = "ta-admin",
  Lead = "tr-lead-user",
  Standard = "tr-standard-user"
}

export interface IUser extends BaseAttributes {
  role: string;
  suspend: boolean;
}

export interface NewUserDetails {
  firstName: string;
  lastName: string;
  email: string;
  temporaryPassword: string;
}

export interface User {
  userId?: string;
  firstName: string;
  lastName: string;
  fullName?: string;
  email: string;
}

export interface UserSignup {
  username: string;
  email: string;
  password: string;
}

export enum roles {
  user = 0,
  lead,
  admin
}

// export interface NewUserDetails extends User {
//   // firstName: string;
//   // lastName: string;
//   // email: string;
//   temporaryPassword: string;
// }
