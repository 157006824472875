import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Action, select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import * as fromRoot from '../../state/app.state';
import * as authActions from './auth.actions';
import { AuthService } from '../authorisation.service';
import { CognitoActions, CognitoErrors } from '../cognito.errors';
import * as fromAuthActions from './auth.actions';

import * as fromNav from '../../navigation/state/navigation.actions';
import { JwtHelperService } from '@auth0/angular-jwt';
import {
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import * as fromAuth from './index';
import { of } from 'rxjs';
import { LocalStorageService } from '@touch-apps/local-storage';
import { AuthActionTypes } from './auth.actions';
import { getAuthState } from './index';

export const AUTH_KEY = 'AUTH';

@Injectable()
export class AuthEffects implements OnInitEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private store: Store<fromRoot.State>,
    private snackbar: MatSnackBar,
    private router: Router,
    private localStorageService: LocalStorageService,
  ) {}

  ngrxOnInitEffects(): Action {
    return new fromAuthActions.Initialise();
  }

  persist = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionTypes.SET_AUTHENTICATED,
          AuthActionTypes.SET_UNAUTHENTICATED,
          AuthActionTypes.SET_ADMIN,
          AuthActionTypes.SET_LEAD,
          AuthActionTypes.SET_USER,
          AuthActionTypes.SET_EMAIL,
          AuthActionTypes.SIGNIN_SUCCESS,
          AuthActionTypes.SIGNUP_SUCCESS),  // todo - add any action here which needs to save to local
        withLatestFrom(this.store.pipe(select(getAuthState))),
        tap(([action, authState]) => {
          console.log('should be saving auth state', authState);
          this.localStorageService.setItem(AUTH_KEY, authState);
        }),
      ),
    { dispatch: false },
  );

  // @Effect({dispatch: false})
  // @ts-ignore
  initialise$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<authActions.Initialise>(authActions.AuthActionTypes.INITIALISE),
        tap((action) => {
          // console.log('effect tap', action);
          this.store.dispatch(new fromAuthActions.SetUnauthenticated());
        }),
        // @ts-ignore
        switchMap((action) => {
          const registeredUser = this.authService.getAuthenticatedUser();
          // @ts-ignore
          registeredUser.getSession((err, session) => {
            if (err) {
              this.router.navigate(['/authorisation/sign-in']);
            }
            if (session.isValid()) {
              this.store.dispatch(new fromAuthActions.SetAuthenticated());
              this.checkForRoles(this.authService.getUserPool());
              // this.router.navigate(['/dashboard']);
            }
          });

          return of(null);
        }),
      ),
    { dispatch: false },
  );

  // @Effect({dispatch: false})
  signin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<authActions.Signin>(authActions.AuthActionTypes.SIGNIN),
        tap((action) => {
          // console.log('effect tap', action);
        }),
        switchMap((action) => {
          return this.authService.signIn$(action.signinDetails).pipe(
            // This tap is for debugging only
            tap((response) => {
              // console.log('response from signUp', response);
              // console.log(
              //   'type is cognito user session',
              //   response instanceof CognitoUserSession
              // );
              if (response instanceof CognitoUserSession) {
                this.store.dispatch(new fromAuthActions.SetAuthenticated());
                this.store.dispatch(new fromAuthActions.SigninSuccess());
                this.checkForRoles(this.authService.getUserPool());
                this.router.navigate(['/dashboard']);
              }
              if (
                response.action &&
                response.action === CognitoActions.NEW_PASSWORD_REQUIRED
              ) {
                this.store.dispatch(
                  new fromAuthActions.SaveUserAttributes(
                    response.userAttributes,
                  ),
                );
                this.store.dispatch(
                  new fromAuthActions.SaveCognitoUser(response.cognitoUser),
                );
                this.snackbar.open('Your password needs to be changed', 'Ok');
                this.router.navigate([
                  '/authorisation/change-password/challenge',
                ]);
              }
              this.store.dispatch(new fromAuthActions.SigninSuccess());
            }),
            catchError((err) => {
              let errorMessage = err.message;
              if (
                err.code === CognitoErrors.NO_USER ||
                err.code === CognitoErrors.NOT_AUTHORISED
              ) {
                errorMessage = 'Bad username or password';
                this.snackbar.open(errorMessage, 'Ok', { duration: 3000 });
              }
              if (
                err.code === CognitoErrors.VALIDATION_EXCEPTION &&
                err.message.search(`/${CognitoErrors.ACCOUNT_SUSPENDED}/`)
              ) {
                errorMessage = 'Your account is suspended';
                this.snackbar.open(errorMessage, 'Ok');
              }

              this.store.dispatch(new fromAuthActions.ClearAuth());
              if (err.code === CognitoErrors.NOT_CONFIRMED) {
                this.store.dispatch(
                  new fromAuthActions.SetEmail(action.signinDetails.email),
                );
                this.snackbar.open(
                  'Your account has not been confirmed yet. Please enter the code you were emailed',
                  'Ok',
                );
                this.router.navigate(['/authorisation/signup-confirm']);
              }
              if (err.code === CognitoErrors.PW_RESET_REQUIRED) {
                this.snackbar.open(
                  'Admin has requested you reset your password. Please complete the reset form',
                  'Ok',
                );
                this.router.navigate(['/authorisation/forgotten-password']);
              }

              return of(null);
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  // @Effect({dispatch: false})
  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<authActions.Logout>(authActions.AuthActionTypes.LOGOUT),
        tap((action) => {
          // console.log('effect tap', action);
          // @ts-ignore
          this.authService.getUserPool().getCurrentUser().signOut();
          this.store.dispatch(new fromNav.ClearMenus());
          this.store.dispatch(new fromAuthActions.ClearAuth());
          this.router.navigate(['/']);
        }),
      ),
    { dispatch: false },
  );

  signup$ = createEffect(() =>
    this.actions$.pipe(
      ofType<authActions.Signup>(authActions.AuthActionTypes.SIGNUP),
      switchMap((action) => {
        return this.authService.signUp(action.signupDetails).pipe(
          // This tap is for debugging only
          tap((response) => {
            // console.log('response from signUp', response);
          }),
          map((registeredUser) => {
            this.snackbar.open('Account created', 'Ok', { duration: 2500 });
            this.router.navigate(['/authorisation/signup-confirm']);
            return new authActions.SignupSuccess(registeredUser);
          }),
          catchError((error: HttpErrorResponse) => {
            this.snackbar.open(error.message, 'Ok', { duration: 2500 });
            if (error.name === CognitoErrors.USER_EXISTS + '') {
              new authActions.UnSetAwaitingAccountConfirm();
              this.router.navigate(['/authorisation/sign-in']);
            }
            return [new authActions.SignupFailure(error.error)];
          }),
        );
      }),
    ),
  );

  // @Effect()
  confirmUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType<authActions.ConfirmUser>(authActions.AuthActionTypes.CONFIRM_USER),
      switchMap((action) => {
        return this.authService.confirmUser(action.username, action.code).pipe(
          // This tap is for debugging only
          tap((response) => {
            // console.log('response from confirmUser', response);
          }),
          map((confirmedUser) => {
            this.snackbar.open(
              'Your account has been confirmed. You can now sign in',
              'Ok',
            );
            this.router.navigate(['/sign-in']);
            return new authActions.ConfirmUserSuccess(confirmedUser);
          }),
          catchError((error: any) => {
            if (error.message.includes('is CONFIRMED')) {
              this.snackbar.open(
                'Your account is already confirmed. Please sign in',
                'Ok',
              );
              this.router.navigate(['/sign-in']);
            }
            if (error.code === CognitoErrors.CODE_MISMATCH) {
              this.snackbar.open(
                'The code you entered is not correct. Please request another',
                'Ok',
              );
            } else {
              this.snackbar.open(error.message, 'Ok', { duration: 2500 });
            }
            return [new authActions.ConfirmUserFailure(error.error)];
          }),
        );
      }),
    ),
  );

  // @Effect()
  resendConfirmationCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType<authActions.ResendConfirmationCode>(
        authActions.AuthActionTypes.RESEND_CONFIRMATION_CODE,
      ),
      switchMap((action) => {
        return this.authService.resendConfirmCode(action.email).pipe(
          // This tap is for debugging only
          tap((response) => {
            // console.log('response from resend confirm code', response);
          }),
          map((confirmedUser) => {
            this.snackbar.open(
              'The new code will be sent to the email address you registered with',
              'Ok',
            );
            return new authActions.ResendConfirmationCodeSuccess();
          }),
          catchError((error: any) => {
            if (error.message.includes('already confirmed')) {
              this.snackbar.open(
                'Your account is already confirmed. Please try to sign in',
                'Ok',
              );
              this.router.navigate(['/sign-in']);
            }
            if (error.code === CognitoErrors.CODE_MISMATCH) {
              this.snackbar.open(
                'The code you entered is not correct. Please request another',
                'Ok',
              );
            } else {
              this.snackbar.open(error.message, 'Ok', { duration: 2500 });
            }
            return [new authActions.ResendConfirmationCodeFailure()];
          }),
        );
      }),
    ),
  );

  // @Effect()
  requestPasswordReset$ = createEffect(() =>
    this.actions$.pipe(
      ofType<authActions.RequestPasswordReset>(
        authActions.AuthActionTypes.REQUEST_PASSWORD_RESET,
      ),
      switchMap((action) => {
        return this.authService.requestPasswordReset(action.email).pipe(
          // This tap is for debugging only
          tap((response) => {
            // console.log('response from request password reset', response);
          }),
          map((confirmedUser) => {
            this.snackbar.open(
              'The new code will be sent to the email address you registered with',
              'Ok',
            );
            return new authActions.RequestPasswordResetSuccess();
          }),
          catchError((error: any) => {
            if (error.code === CognitoErrors.LIMIT_REACHED) {
              this.snackbar.open(
                'You have had too many reset attempts. Please try later',
                'Ok',
              );
            }
            if (error.code === CognitoErrors.NO_USER) {
              // todo - maybe not show anything here
              this.snackbar.open('No such user', 'Ok');
            } else {
              this.snackbar.open(error.message, 'Ok', { duration: 2500 });
            }
            return [new authActions.RequestPasswordResetFailure()];
          }),
        );
      }),
    ),
  );

  // @Effect()
  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType<authActions.ResetPassword>(
        authActions.AuthActionTypes.RESET_PASSWORD,
      ),
      switchMap((action) => {
        return this.authService
          .resetPasswordWithVerificationCode(action.code, action.newPassword)
          .pipe(
            // This tap is for debugging only
            tap((response) => {
              // console.log('response from reset password ', response);
            }),
            map(() => {
              this.snackbar.open(
                'Your password has been changed. You can now login using it',
                'Ok',
              );
              this.router.navigate(['/authorisation/sign-in']);
              return new authActions.ResetPasswordSuccess();
            }),
            catchError((error: any) => {
              if (
                error.code === CognitoErrors.CODE_MISMATCH ||
                error.code === CognitoErrors.INVALID_PARAM
              ) {
                this.snackbar.open(
                  'There is a problem with the code you supplied. Please try the request again',
                  'Ok',
                );
              } else {
                this.snackbar.open(
                  'There has been an error resetting your password. Please try again later',
                  'Ok',
                );
              }
              return [new authActions.ResetPasswordFailure()];
            }),
          );
      }),
    ),
  );

  // @Effect()
  changePasswordChallenge$ = createEffect(() =>
    this.actions$.pipe(
      ofType<authActions.ChangePasswordChallenge>(
        authActions.AuthActionTypes.CHANGE_PASSWORD_CHALLENGE,
      ),
      withLatestFrom(
        this.store.select(fromAuth.getUserAttributes),
        this.store.select(fromAuth.getCognitoUser),
      ),
      switchMap(([action, userAttributes, cognitoUser]) => {
        return this.authService
          .changePasswordChallenge(
            action.newPassword,
            userAttributes,
            cognitoUser,
          )
          .pipe(
            // This tap is for debugging only
            tap((response) => {
              // console.log('response from change password challenge ', response);
            }),
            map(() => {
              this.snackbar.open('Your password has been changed.', 'Ok');
              this.store.dispatch(new fromAuthActions.SetAuthenticated());
              // this.store.dispatch(new authActions.ChangePasswordChallengeSuccess());
              this.checkForRoles(this.authService.getUserPool());
              this.router.navigate(['/dashboard']);
              return new authActions.ChangePasswordChallengeSuccess();
            }),
            catchError((error: any) => {
              this.snackbar.open(error.message, 'Ok');
              this.router.navigate(['/authorisation/sign-in']);
              return of(new authActions.ChangePasswordChallengeFailure());
            }),
          );
      }),
    ),
  );

  // @Effect()
  changePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType<authActions.ChangePassword>(
        authActions.AuthActionTypes.CHANGE_PASSWORD,
      ),

      switchMap((action) => {
        return this.authService
          .changePassword(action.oldPassword, action.newPassword)
          .pipe(
            // This tap is for debugging only
            tap((response) => {
              // console.log('response from change password', response);
            }),
            map(() => {
              this.snackbar.open('Your password has been changed.', 'Ok');
              this.router.navigate(['/dashboard']);
              return new authActions.ChangePasswordSuccess();
            }),
            catchError((error: any) => {
              this.snackbar.open(error.message, 'Ok');
              return of(new authActions.ChangePasswordFailure());
            }),
          );
      }),
    ),
  );

  checkForRoles(userPool: CognitoUserPool) {
    this.clearRoles();
    // @ts-ignore
    const groups = this.decodeToken(userPool)['cognito:groups'];
    if (groups) {
      for (const group of groups) {
        switch (group) {
          case 'ta-admin':
            this.store.dispatch(new fromAuthActions.SetAdmin());
            this.store.dispatch(new fromAuthActions.SetLead());
            this.store.dispatch(new fromAuthActions.SetUser());
            this.store.dispatch(new fromNav.SetAdminMenus());
            break;
          case 'tr-lead-user':
            this.store.dispatch(new fromAuthActions.SetLead());
            this.store.dispatch(new fromAuthActions.SetUser());
            this.store.dispatch(new fromNav.SetLeadMenus());
            break;
          case 'tr-standard-user':
            this.store.dispatch(new fromAuthActions.SetUser());
            this.store.dispatch(new fromNav.SetUserMenus());
            break;
          default:
            break;
        }
      }
    }
  }

  clearRoles() {
    this.store.dispatch(new fromAuthActions.UnSetAdmin());
    this.store.dispatch(new fromAuthActions.UnSetLead());
    this.store.dispatch(new fromAuthActions.UnSetUser());
  }

  decodeToken(userPool: CognitoUserPool) {
    let sessionIdInfo = null;
    // @ts-ignore
    userPool.getCurrentUser().getSession((err, session) => {
      if (err) {
        console.log('Error getting session', err);
      }
      if (session.isValid()) {
        sessionIdInfo = new JwtHelperService().decodeToken(
          session.getIdToken().jwtToken,
        );
      }
    });
    return sessionIdInfo;
  }
}
