<footer
  class="footer"
  *ngIf="showFooter$ | async"
>
  <div class="links" >
    <a href="https://www.github.com/inhand" target="_blank">
<!--      <mat-icon fontSet="material-icons-outlined" >code</mat-icon>-->
      <mat-icon svgIcon="github" ></mat-icon>
      <span>Github</span>
    </a>
    <a href="https://www.twitter.com/inhand" target="_blank">
<!--      <mat-icon fontSet="material-icons-outlined" >campaign</mat-icon>-->
      <mat-icon svgIcon="twitter" ></mat-icon>
      <span>X (twitter)</span>
    </a>
    <a href="http://inhand.co.uk" target="_blank">
      <mat-icon svgIcon="inhand" ></mat-icon>
      <span>Inhand</span>
    </a>
  </div>

  <div class="signature">
    &#169; <span class="year" data-testid="footer-year">2014-2024</span> -
    InHand Systems Limited
    <br class="d-block d-sm-none" />
    <a
      [matTooltip]="'Changelog'"
      matTooltipPosition="before"
      href="http://inhand.co.uk"
    >

      <mat-icon fontSet="material-icons-outlined">rocket_launch</mat-icon>
      V0.1
    </a>
  </div>
</footer>
