import { MenuItem } from '../navigation.model';
import {
  NavigationActions,
  NavigationActionTypes,
} from './navigation.actions';

export interface NavigationState {
  headerMenuItems: MenuItem[];
  sideMenuItems: MenuItem[];
  showFooter: boolean;
}

const initialState: NavigationState = {
  headerMenuItems: [],
  sideMenuItems: [],
  showFooter: true
};

export function navigationReducer(state = initialState, action: NavigationActions) : NavigationState {
  switch (action.type) {
    case NavigationActionTypes.CLEAR_HEADER:
      return {
        ...state,
        headerMenuItems: []
      };
    case NavigationActionTypes.CLEAR_SIDEMENU:
      return {
        ...state,
        sideMenuItems: []
      };
    case NavigationActionTypes.SET_HEADER:
      return {
        ...state,
        headerMenuItems: action.payload
      };
    case NavigationActionTypes.SET_SIDEMENU:
      return {
        ...state,
        sideMenuItems: action.payload
      };
    case NavigationActionTypes.SET_FOOTER_VIEW:
      return {
        ...state,
        showFooter: action.canView
      };
     default: {
      return state;
    }
  }
}


