export enum CognitoErrors {
  NOT_CONFIRMED = <any> 'UserNotConfirmedException',
  NO_USER = <any> 'UserNotFoundException',
  NOT_AUTHORISED = <any> 'NotAuthorizedException',
  USER_EXISTS = <any> 'UsernameExistsException',
  LIMIT_REACHED = <any> 'LimitExceededException',
  CODE_MISMATCH = <any> 'CodeMismatchException',
  PW_RESET_REQUIRED = <any> 'PasswordResetRequiredException',
  INVALID_PARAM = <any> 'InvalidParameterException',
  VALIDATION_EXCEPTION = <any> 'UserLambdaValidationException',
  ACCOUNT_SUSPENDED = <any> 'taAccountSuspendedException'
}

export enum CognitoActions {
  NEW_PASSWORD_REQUIRED=<any> 'NewPasswordRequired',
  CUSTOM_CHALLENGE = <any> 'CustomChallenge'
}
